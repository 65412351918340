<template>
  <div>
    <html-editor insertMiddleAds />
  </div>
</template>

<script>
import HTMLEditor from "../components/quill/html-editor"

export default {
  name: "TestingPage",
  components: {
    "html-editor": HTMLEditor
  },
  computed: {},
  data() {
    return {}
  },
  methods: {},
  mounted() { }
}
</script>